import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useSelector } from "react-redux";
import {Trans,  useTranslation } from 'react-i18next';

import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';

export function PasswordReset() {
    const [state, setState] = useState({ code: "", error: false });

    const [validToken] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))

    useEffect(()=>{
        if(isLoggedIn === true && validToken !== null){
            window.location.replace(searchParams.get("redirect"));
        }
    },[isLoggedIn, validToken, searchParams]);

    const handleRegesterClick = (e) => {
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(state.code !== "") {
            setState({
                ...state,
                error: false
            });
        } else {
            setState({
                ...state,
                error: true
            });
            return;
        }
        //navigate("/login");
    };

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            error: false
        });
    };

    const handleInvalid = (e) => {
        if(e.target.value === ""){
            //e.target.setCustomValidity(t("Please fill in the field"));
        }else{
            //e.target.setCustomValidity("");
        }
    };

    


    return (
            <Frame>
                <div className='main main-password-reset'>
                    <div className='right-main'>
                        <h1>{t("Password reset successfully")}</h1>
                        <h2>{t("i2")}</h2>
                    </div>
                    <div className='left-main'>
                        <img src='/images/person-pointing.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </Frame>
    );
}