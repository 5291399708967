import {React} from 'react';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import "./styles.css";
import {Login} from './components/pages/Login';
import {LoginSimCustomers} from './components/pages/LoginSimCustomers';
import {Start} from './components/pages/Start';
import {LoginVerify} from './components/pages/LoginVerify';
import {VerifyPhone} from './components/pages/VerifyPhone';
import {ResetPassword} from './components/pages/ResetPassword';
import {RestoreUsername} from './components/pages/RestoreUsername';
import {CreatePassword} from './components/pages/CreatePassword';
import {PasswordReset} from './components/pages/PasswordReset';
import {VerificationCodeType} from './components/pages/VerificationCodeType';
import {SuccessfullyLoggedIn} from './components/pages/SuccessfullyLoggedIn';

import HandleURLParameters from './components/routes/HandleURLParameters';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import {Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button} from '@mui/material';
import { hideErrorDialog } from "./features/errorDialog";

function App() {
  const user = useSelector((state) => state.user.value);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorDialog = useSelector((state) => state.errorDialog.value);

  const handleErrorDialogClose = () => {
    dispatch(hideErrorDialog());
  };

  return (
    <>
      <Router>
        <Switch> 
          <Route path='/' element={<HandleURLParameters />}>
            <Route path='/' element={<Start />} />
            <Route path='/login' element={<Login />} />
            <Route path='/start' element={<Start />} />
            <Route path='/login-verify' element={<LoginVerify />} />
            <Route path='/verify-phone' element={<VerifyPhone />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/restore-username' element={<RestoreUsername />} />
            <Route path='/create-password' element={<CreatePassword />} />
            <Route path='/password-reset' element={<PasswordReset />} />
            <Route path='/verification-code-type' element={<VerificationCodeType />} />
            <Route path='/successfully-logged-in' element={<SuccessfullyLoggedIn />} />
          </Route>
          <Route path='/login-sim-customers' element={<LoginSimCustomers />} />
        </Switch>
      </Router>
      <Dialog open={errorDialog.isOpen} onClose={handleErrorDialogClose}>
        <DialogTitle>{t(errorDialog.title)}</DialogTitle>
        <DialogContent>
        <Typography component="div">{t(errorDialog.text)}</Typography>
        </DialogContent>
        <DialogActions> 
          <Button  variant="contained"onClick={handleErrorDialogClose} size="large">{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default App;
